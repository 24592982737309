require('./src/styles/global.scss');

function initGTM() {
    if (window.isGTMLoaded) {
        return false;
    }
    if (!window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
        setTimeout(() => {
            window.dataLayer.push({
                'event': 'route-change',
            });
        }, 50)
    }
    window.isGTMLoaded = true;

    const script = document.createElement('script');
    
    script.async = true;
    script.src = `${process.env.GATSBY_GTM_ORIGIN_URL}/gtm.js?id=${process.env.GATSBY_GTM_TRACKING_ID}`;

    script.onload = () => {
        function gtag() {
            dataLayer.push(arguments); // eslint-disable-line
        }

        gtag('js', new Date());
        gtag('config', `${process.env.GATSBY_GTM_TRACKING_ID}`);
    };

    document.head.appendChild(script);
}

function loadGTM(event) {
    initGTM();
    event.currentTarget.removeEventListener(event.type, loadGTM);
}

exports.onClientEntry = () => {
    document.onreadystatechange = () => {
        if (document.readyState !== 'loading') {
            setTimeout(initGTM, 5000);
        }
    };

    document.addEventListener('scroll', loadGTM, {passive: true});
    document.addEventListener('click', loadGTM, {passive: true});
    document.addEventListener('touchstart', loadGTM, {passive: true});
};

exports.onRouteUpdate = () => {
    if (!window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
        setTimeout(() => {
            window.dataLayer.push({
                'event': 'route-change',
            });
        }, 50)
    }
    if (window.isGTMLoaded) {
        setTimeout(() => {
            window.dataLayer.push({
                'event': 'route-change',
            });
        }, 50)
    }
}
